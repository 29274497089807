import * as Yup from "yup";

const client_fields = {
  email: {
    name: "email",
    label: "Client Email Address*",
    helperText: "Use your organizational email for a smoother process",
    test: Yup.string()
      .required("Email address is required")
      .email("A valid email address is required"),
  },
  name: {
    name: "name",
    label: "Client Name",
    helperText: "Full name is preferable",
    test: Yup.string().required("Your name is required").min(3),
  },
  phone: {
    name: "phone",
    label: "Phone",
    test: Yup.string().required("A contact phone number is required").min(5),
  },
  refer: {
    name: "refer",
    label: "Referral",
    helperText: `How did you hear about us? We'd like to thank our referral network!`,
  },
  org: {
    name: "org",
    label: "Organization/Affiliation",
    helperText: "What organization needs work done?",
    test: Yup.string().required("Organization is required"),
  },
  serv: {
    name: "serv",
    initial_value: [],
    test: Yup.array().min(1, "Please select at least one type of service"),
  },
  brief: {
    name: "brief",
    label: "Brief",
    helperText: "Tell us a bit about your project.",
    test: Yup.string().required("Brief is required").min(5),
  },
  agreed: {
    name: "agreed",
    label: (
      <span>
        I agree with the{" "}
        <a target="_blank" href="/legal/tos.html">
          Terms of Service
        </a>{" "}
        &amp;{" "}
        <a target="_blank" href="/legal/privacy.html">
          Privacy Policy
        </a>
      </span>
    ),
    initial_value: false,
    test: Yup.bool().notOneOf([false], "You must agree to sign up"),
  },
};

// const initialValues = Object.entries(formField)
export { client_fields };
