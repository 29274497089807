import {
  Button,
  createStyles,
  FormGroup,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import {
  CheckboxField,
  InputField,
} from "src/components/formik_material_inputs";

import { client_fields } from "./fields";
import * as Yup from "yup";
import ServicesFormGroup from "../shared/services-form-group";
import React from "react";

enum SignupSteps {
  CONTACT_INFO = 0,
  SERVICES,
}
const SignupStepsKeys = Object.keys(SignupSteps).filter((v) =>
  isNaN(v as any)
) as Array<string>;

const LastSignupStep = SignupSteps.SERVICES;

const StepFormMap = {
  [SignupSteps.CONTACT_INFO]: ContactForm,
  [SignupSteps.SERVICES]: ServicesForm,
};

const { email, name, phone, refer } = client_fields;
export function ContactForm() {
  return (
    <FormGroup>
      <Typography variant="body2" align="center" gutterBottom>
        <Button variant="outlined" size="small" href="/signup/provider/">
          Signup as a Provider
        </Button>
      </Typography>
      <InputField autoFocus type="email" autoComplete="email" {...email} />
      <InputField autoComplete="name" {...name} />
      <InputField autoComplete="tel" {...phone} />
      <InputField {...refer} />
    </FormGroup>
  );
}
ContactForm.form_fields = [email, name, phone, refer];

const { org, serv, brief, agreed } = client_fields;

const serviceFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    tos: {
      "& a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
    },
  })
);

export function ServicesForm() {
  const classes = serviceFormStyles();
  return (
    <>
      <ServicesFormGroup field={serv} />
      <FormGroup>
        <InputField {...org} />
        <InputField multiline {...brief} />
        <CheckboxField {...agreed} className={classes.tos} />
      </FormGroup>
    </>
  );
}

ServicesForm.form_fields = [org, serv, brief, agreed];

export function validationSchemeForStep(step: SignupSteps) {
  const field_names = (StepFormMap[step].form_fields as Array<any>).map(
    (f) => f.name
  );

  return Yup.object().shape(
    Object.entries(client_fields).reduce((acc, v) => {
      if ("test" in v[1] && field_names.indexOf(v[1].name) > -1) {
        acc[v[0]] = v[1].test;
      }
      return acc;
    }, {})
  );
}

export function initialValuesForStep() {
  // const field_names = (StepFormMap[step].form_fields as Array<any>).map(f => f.name)
  // We don't want to filter initial values because that messes up Formik in case we switch between steps

  return Object.entries(client_fields).reduce((acc, v) => {
    if ("initial_value" in v[1]) {
      acc[v[0]] = v[1].initial_value;
    } else {
      acc[v[0]] = "";
    }
    return acc;
  }, {});
}

export { SignupSteps, LastSignupStep, SignupStepsKeys, StepFormMap };
